<template>
  <div class="d-flex align-items-center">
    <b-form-group :label="translations.filters.rvp">
      <b-form-select v-model="filter_set.rvp_uuid"
        :disabled="loading"
        :options="filter_options.rvps"></b-form-select>
    </b-form-group>
    <b-form-group :label="translations.filters.start_end_date" class="">
      <vue-ctk-date-time-picker format="YYYY-MM-DD"
        data-testid="start-end-datepicker"
        :label="translations.filters.start_end_date_placeholder"
        formatted="ll"
        range
        only-date
        :error="!dateRangeValidator(filter_set.start_end_date)"
        v-model="filter_set.start_end_date"/>
    </b-form-group>
    <b-button
      class="mt-2"
      variant="primary"
      @click="applyFilters">
      {{ translations.filters.search }}
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import service from '@/services/commission';

export default {
  name: 'StatementFilters',
  data() {
    return {
      loading: false,
      translations: translations.commissions.statements,
      filter_set: {
        rvp_uuid: '',
        start_end_date: null,
      },
      filter_options: {
        rvps: [
          { value: '', text: translations.commissions.statements.filters.default_rvp_filter },
        ],
      },
    };
  },
  async beforeMount() {
    await this.loadRvps();
  },
  methods: {
    recoverFiltersFromURL(filters) {
      this.filter_set = { ...filters };
    },
    applyFilters() {
      this.$emit('apply-filters', this.filter_set);
    },
    async loadRvps() {
      this.loading = true;
      try {
        const data = await service.getRVPs({
          order_by: 'name',
        });

        const responseFilters = data.rvps.map(rvp => ({ value: rvp.uuid, text: rvp.name })) ?? [];
        this.filter_options.rvps = [ ...this.filter_options.rvps, ...responseFilters ];
      } catch (err) {
        this.$noty.error(this.translations.errors.loading_filters);
      }
      this.loading = false;
    },
    dateRangeValidator(field) {
      return !field || (field.start && field.end);
    },
  },
};
</script>
